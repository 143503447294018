<template>
  <b-card class="row-height" no-body>
    <div>
      <b-card no-body class="card-statistics" style="">
        <b-card-body class="statistics-body">
          <h2 class="text-purple font-weight-bold mb-1">
            <b-link class="btn btn-outline-primary" to="/">
              <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
              {{ $t("back") }}
            </b-link>
            {{ $t("deposit_funds") }}
          </h2>
          <br/><br/>
          {{ $t("deposit_subtitle") }} <br/>
          <br/>
          <b-col class="col-md-12 row mb-1">

            <b-dropdown id="dropdown-custom-options" class="mb-2" style="width: 200px;" variant="outline-dark">

              <template #button-content>
                <span v-if="deposit_currency">{{ deposit_currency }}</span>
                <span v-else id="dropdown_text">{{ $t('select_currency') }}</span>
              </template>

              <b-dropdown-item
                  v-for="option in currencies"
                  :key="option.id"
                  @click="changeCurrency(option.name, option.id, option.type, option.code, null)"
                  v-if="option.code != 'USDT'"
              >
                <span>{{ option.name }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeCurrency('USDT', 14, 'crypto', 'USDT', 'erc')">USDT(ERC)</b-dropdown-item>
              <b-dropdown-item @click="changeCurrency('USDT', 14, 'crypto', 'USDT', 'trc')">USDT(TRC)</b-dropdown-item>

            </b-dropdown>

          </b-col>

          <b-col class="col-md-12 row mb-2">
            <div class="col-md-3 col-ms-6 col-xs-12 pl-0 mb-1">
              <b-form-input
                  v-model.trim="deposit_amount"
                  :placeholder="placeholder"
                  class="mr-1"
                  autocomplete="off"
              ></b-form-input>
            </div>
            <div>
              <b-button variant="primary" size="small" class="ml-md-2" :disabled="disableBtn" @click="submitDeposit()">
                {{ $t("submit") }}
              </b-button>
              <img height="25" v-if="disableBtn" src="/new-loader.svg"/>
            </div>
          </b-col>

          {{ $t("deposit_introductions") }}
          <b-row>
            <b-col class="col-md-6 col-sm-12">
              <b-row>
                <b-col
                    v-if="currencyType != 'crypto' || item.text != 'bic'"
                    v-for="(item, index) in invoiceData"
                    :key="index"
                    class="mb-2 mb-md-0 col-md-12 mt-3 mb-3"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" :variant="'light-' + item.variant">
                        <feather-icon size="24" :icon="item.icon"/>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto row">
                      <h4 class="col-md-3 font-weight-bolder"
                          v-if="(item.text != 'bank_name' && item.text != 'IBAN') || currencyType != 'crypto'">
                        {{ $t(item.text) }}
                      </h4>
                      <h4 class="col-md-3 font-weight-bolder"
                          v-else-if="currencyType == 'crypto' && item.text == 'IBAN'">
                        Chain
                      </h4>

                      <h4 class="col-md-3 font-weight-bolder" v-else>
                        Wallet
                      </h4>
                      <h4
                          v-if="item.text == 'IBAN'"
                          class="col-md-6 font-weight-bolder mb-0"
                      >
                        <span v-if="usdtVariant == 'erc'">(ERC20)0xD36eDA3eAAa50aE13fc354B5DE06862BDD8A9387</span>
                        <span v-else-if="usdtVariant == 'trc'">(TRC20)TSH6YiASw9m8rnb7EH3uckc6MezmYqFTrr</span>
                        <span v-else>{{ selected_iban }}</span>

                      </h4>

                      <h4
                          v-else-if="item.text == 'bank_name' && currencyType == 'crypto'"
                          class="col-md-6 font-weight-bolder mb-0"
                      >

                        Aequitex {{ currencyName }} Wallet

                      </h4>

                      <h4
                          v-else
                          class="col-md-6 font-weight-bolder mb-0"
                          v-html="item.value"
                      ></h4>

                      <h6>
                        <b-button :disabled="disabledCopy" @click="copyItem(item,index)" variant="outline-primary"
                                  size="small" class="ml-2" :id="'copy-button-' + index">
                          <feather-icon
                              icon="CopyIcon"
                              class="primary"
                              variant="primary"
                              size="14"
                          />
                          <b-tooltip :target="'copy-button-' + index" triggers="click"
                                     :show.sync="showCopiedTooltip[index]"
                                     placement="top">
                            Copied!
                          </b-tooltip>
                        </b-button>
                      </h6>
                    </b-media-body>
                  </b-media>

                </b-col>
              </b-row>
              <br/><br/><br/>
              <b-col class="alert alert-primary p-2 col-md-12">
                <div class="row ml-1">
                  <feather-icon variant="primary" size="20" icon="InfoIcon"/>
                  <h5 class="text-primary ml-1">
                    {{ $t("deposit_important_note") }}
                  </h5>
                </div>
              </b-col>
            </b-col>
            <b-col class="col-md-6 col-sm-12">
              <div class="row d-flex">
                <div class="border-right">
                  <div v-if="showQR" class="new-page" style="page-break-before: always;height: auto;">
                    <div v-html="QR.qr" style="margin-left: 20px; width: 100%;">
                    </div>
                  </div>
                </div>
                <div class="text-end justify-end">
                  <div v-if="showSepaQR" class="" style="">
                    <div class="text-center"><strong>EU Sepa Payment</strong></div>
                    <div style="margin-left: 20px; width: 100%;">
                      <img :src="sepaQR.qr">
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <br/>
      <br/><br/>
    </div>
    <b-modal
        v-model="successDepositModal"
        style="padding-top: 20vh;"
        scrollable
        size="md"
    >
      <template #modal-footer="{}" class="p-0 m-0">
        <p class="d-none">-</p>
      </template>
      <div>
     <span class="d-flex p-1 alert alert-info">
      <feather-icon variant="primary" size="60" class="mr-2" icon="InfoIcon"/>
      <h6 class=" p-0 m-0 ">{{ $t("success_deposit") }}</h6>
     </span>

      </div>
    </b-modal>
  </b-card>

</template>
<script>
import {ValidationProvider, ValidationObserver,} from "vee-validate";
import i18n from "@/libs/i18n";
import {CurrencyAmountFormat} from '@core/utils/CurrencyAmountFormat';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      disableBtn: false,
      ibans_array: [
        {
          currency_id: 1,
          iban: "CH84 0026 7267 1195 0102 E"
        }
      ],
      selected_iban: null,
      refference_num: '',

      SwitzerlandData: [
        {
          text: "account",
          value: 'Aequitex AG, Gubelstrasse 24, 6300 Zug, Switzerland',
          icon: "MapPinIcon",
          variant: "success",
        },
        {
          text: "IBAN",
          value: "CH84 0026 7267 1195 0102 E",
          icon: "CreditCardIcon",
          variant: "primary",
        },
        {
          text: "bank_name",
          value: "UBS",
          icon: "HomeIcon",
          variant: "secondary",
        },
        {
          text: "bic",
          value: "UBSWCHZH80A",
          icon: "CreditCardIcon",
          variant: "info",
        },
        {
          text: "reference_number_payment_reason",
          value: this.$store.state.auth.user.id,
          icon: "DollarSignIcon",
          variant: "success",
        },
      ],

      anotherCountryData: [
        {
          text: "account",
          value: 'Aequitex AG, Gubelstrasse 24, 6300 Zug, Switzerland',
          icon: "MapPinIcon",
          variant: "success",
        },
        {
          text: "IBAN",
          value: "GB82REVO00996901193139",
          icon: "CreditCardIcon",
          variant: "primary",
        },
        {
          text: "bank_name",
          value: "Revolut Ltd",
          icon: "HomeIcon",
          variant: "secondary",
        },
        {
          text: "bic",
          value: "REVOGB21",
          icon: "CreditCardIcon",
          variant: "info",
        },
        {
          text: "reference_number_payment_reason",
          value: this.$store.state.auth.user.id,
          icon: "DollarSignIcon",
          variant: "success",
        },
      ],
      invoiceData: null,
      data: null,
      deposit_currency: null,
      deposit_currency_id: null,
      deposit_currency_code: null,
      deposit_amount: null,
      showCopiedTooltip: [],
      // disabledCopy: true,
      disabledCopy: false,
      QR: null,
      showQR: false,
      sepaQR: null,
      showSepaQR: false,
      successDepositModal: false,
      currencies: null,
      currencyType: null,
      currencyName: null,
      usdtVariant: null,
      placeholder: null

      // AmountSelectFields: [
      //   { value: 'CHF', text: 'CHF', flag: require('@/assets/images/currency/chf.png') },
      //   { value: 'EUR', text: 'EUR', flag: require('@/assets/images/currency/eur.png') },
      //   { value: 'USD', text: 'USD', flag: require('@/assets/images/currency/usd.png') },
      //   { value: 'GBP', text: 'GBP', flag: require('@/assets/images/currency/gbp.png') },
      //   { value: 'BTC', text: 'BTC', flag: require('@/assets/images/currency/btc.png') },
      //   { value: 'ETH', text: 'ETH', flag: require('@/assets/images/currency/eth.png') },
      //   { value: 'USDT', text: 'USDT', flag: require('@/assets/images/currency/usdt.png') },
      // ],

    };
  },
  created() {
    this.changeIban(1);
    this.getCurrencies();
    this.getData();
    this.getIbans();
    this.invoiceData = this.SwitzerlandData;
    this.placeholder = this.amountFormat(1234.56, "CHF", 'type')
  },

  mounted() {

  },

  methods: {

    amountFormat(amount, currency, type) {
      return CurrencyAmountFormat(amount, currency, type)
    },

    getCurrencies() {
      this.$http.post("/currency").then((response) => {
        if (response.data.status == 200) {
          this.currencies = response.data.value;
        }
      });
    },

    getIbans() {
      this.$http.post("/currency/get-iban").then((response) => {
        if (response.data.status == 200) {
          this.ibans_array = [];
          response.data.value.map((item) => {

            this.ibans_array.push({
              currency_id: item.currency_id,
              iban: item.iban,
            });

          });
          this.changeIban(1);

        }
      });
    },

    changeCurrency(value, id, type, code, usdtType) {
      this.usdtVariant = null;

      if (usdtType) {
        this.usdtVariant = usdtType
      }

      this.currencyName = value

      //  12 crypto
      // VM9965 Deposit.vue:342  13 crypto
      // VM9965 Deposit.vue:342  14 crypto

      this.currencyType = null

      if (code) {
        this.deposit_currency_code = code
      }

      if (type == "crypto") {
        this.currencyType = type
      }


      let country = this.$store.state.auth.user.country.name;

      if (value) {
        this.deposit_currency = value;
      }

      if (id) {
        this.deposit_currency_id = id
      }

      if (!value || value == 'CHF') {
        this.invoiceData = this.SwitzerlandData
      } else {
        if (country !== "Switzerland") {
          // this.invoiceData = this.anotherCountryData
          this.invoiceData = this.SwitzerlandData
        }
      }

      this.placeholder = this.amountFormat(1234.56, code, 'type')

      this.changeIban(id);

    },

    changeIban(id) {
      this.selected_iban = this.ibans_array.find(item => item.currency_id === id).iban;
    },

    getQR() {
      this.$http.post("/buyer/depositQR", {
        deposit_amount: this.deposit_amount,
      }).then((res) => {
        if (res.data) {
          console.log('dataaa', res.data)
          this.showQR = true;
          this.QR = res.data.value;
        }
      });
    },
    getSepaQR() {
      this.$http.post("/buyer/depositSepaQR", {
        deposit_amount: this.deposit_amount,
        currency_id: this.deposit_currency_id
      }).then((res) => {
        if (res.data) {
          console.log('dataaa', res.data)
          this.showSepaQR = true;
          this.sepaQR = res.data.value;
        }
      });
    },
    getData() {
      this.$http
          .post(`/company/show/${this.$store.state.auth.user.company_id}`)
          .then((res) => {
            this.data = res.data.value;
            this.refference_num =
                this.$store.state.app.company &&
                this.$store.state.app.company.value &&
                this.$store.state.app.company.value.main_contact
                    ? this.$store.state.app.company.value.main_contact.id
                    : res.data.value.main_contact.id;

            // this.invoiceData = [
            //   {
            //     text: "account",
            //     value:
            //         "Aequitex AG, " +
            //
            //         "Gubelstrasse 24, " +
            //
            //         "6300 Zug, Switzerland",
            //     icon: "MapPinIcon",
            //     variant: "success",
            //   },
            //   {
            //     text: "IBAN",
            //     value: "CH84 0026 7267 1195 0102 E",
            //     icon: "CreditCardIcon",
            //     variant: "primary",
            //   },
            //   {
            //     text: "bank_name",
            //     value: "UBS",
            //     icon: "HomeIcon",
            //     variant: "secondary",
            //   },
            //   {
            //     text: "bic",
            //     value: "UBSWCHZH80A",
            //     icon: "CreditCardIcon",
            //     variant: "info",
            //   },
            //   {
            //     text: "reference_number_payment_reason",
            //     value: this.refference_num,
            //     icon: "DollarSignIcon",
            //     variant: "success",
            //   },
            // ];
            this.showCopiedTooltip = new Array(this.invoiceData.length).fill(false);
          });
    },
    copyToClipboard(text, index) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$set(this.showCopiedTooltip, index, true);
      setTimeout(() => {
        this.$set(this.showCopiedTooltip, index, false);
      }, 1000);
    },
    copyItem(item, index) {
      let ItemValue = item.value;
      if (item.text == "IBAN") {
        if (this.usdtVariant) {
          if (this.usdtVariant == 'erc') {
            // item.value = '(ERC20)0xD36eDA3eAAa50aE13fc354B5DE06862BDD8A9387'
            ItemValue = '(ERC20)0xD36eDA3eAAa50aE13fc354B5DE06862BDD8A9387'
          } else if (this.usdtVariant == 'trc') {
            // item.value = '(TRC20)TSH6YiASw9m8rnb7EH3uckc6MezmYqFTrr'
            ItemValue = '(TRC20)TSH6YiASw9m8rnb7EH3uckc6MezmYqFTrr'
          }
        } else {
          // item.value = this.selected_iban
          ItemValue = this.selected_iban
        }
      }

      if (this.currencyType == 'crypto' && item.text == 'bank_name') {
        // item.value = `Aequitex ${this.currencyName} Wallet`
        ItemValue = `Aequitex ${this.currencyName} Wallet`
      }


      this.copyToClipboard(ItemValue, index);
    },
    submitDeposit() {
      this.disableBtn = true;
      this.$http
          .post(`/buyer/deposit`, {
            deposit_amount: this.deposit_amount,
            deposit_currency: this.deposit_currency_id
          })
          .then((res) => {
            if (res) {
              this.disableBtn = false;
              if (res.data.status == 200) {
                this.successDepositModal = true;
                this.disabledCopy = false;
                // this.getQR();
                this.getSepaQR();
              } else {
                this.disableBtn = false;
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: res.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.getData();
            }
          });
    },
    showSuccessMessage(message, type) {
      let text = this.$t(message);
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });


    },
  },
};
</script>